import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/bootstrap-vue.scss';
import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueMq from 'vue-mq';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import interceptorsSetup from '@/middleware/axios-interceptors';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

interceptorsSetup();

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

Vue.use(IconsPlugin);

Vue.use(VueAxios, axios);

Vue.use(VueMq, {
  breakpoints: {
    mb: 460,
    sm: 769,
    md: 1250,
    lg: Infinity,
  },
});

Vue.use(VueSweetalert2);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
