import Vue from 'vue';
import VueSession from 'vue-session';

const optionsSession = {
  persist: false,
};

Vue.use(VueSession, optionsSession);

export default {
  getUserPersistent() {
    if (Vue.prototype.$session) {
      if (!Vue.prototype.$session.get('middleware-user-authenticate')) {
        return {
          user: {
            _id: null,
            username: null,
            type: null,
            nickname: null,
            source: null,
            level: null,
            wallet: null,
            commission: null,
          },
          token: '',
        };
      }
      return JSON.parse(Vue.prototype.$session.get('middleware-user-authenticate'));
    }
    return {
      user: {
        _id: null,
        username: null,
        type: null,
        nickname: null,
        source: null,
        level: null,
        wallet: null,
        commission: null,
      },
      token: '',
    };
  },
  clearUserPersistent() {
    Vue.prototype.$session.destroy('middleware-user-authenticate');
    delete Vue.axios.defaults.headers.common.Authorization;
  },
  getUserBalance() {
    if (!Vue.prototype.$session.get('middleware-user-balance')) return 0;
    return Vue.prototype.$session.get('middleware-user-balance');
  },
  currencyFormatter(balance: number) {
    const x = balance;
    if (x === 0) {
      return '0.00';
    } if (!x) {
      return x;
    }
    return x.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  },
  colorCurrency(value: any) {
    if (value < 0) return 'red-text';
    return '';
  },
  colorWinloss(value: any) {
    if (value > 0) return 'green-text';
    if (value < 0) return 'red-text';
    return 'blue-text';
  },
  winloseStatus(value: any) {
    if (value > 0) return 'Win';
    if (value < 0) return 'Loss';
    return 'Tie';
  },
};
