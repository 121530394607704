/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import NodeRSA from 'node-rsa';
import { PUBLIC_ASG_KEY, ASG_SECRET } from '@/utils/api';

const SENSITIVE_KEY = ['password'];

const RSA = new NodeRSA(PUBLIC_ASG_KEY);
localStorage.setItem('x-authorize', RSA.encrypt(ASG_SECRET, 'base64'));

export default function setup() {
  axios.interceptors.request.use(async (request) => {
    request.headers['x-authorize'] = localStorage.getItem('x-authorize');

    if (request.data) {
      for (let idx = 0; idx < Object.keys(request.data).length; idx += 1) {
        const key = Object.keys(request.data)[idx];
        if (SENSITIVE_KEY.includes(key)) {
          request.data = Object.assign(request.data, { [`${key}`]: RSA.encrypt(request.data[key], 'base64') });
          if (key === 'subPassword') localStorage.setItem('x-secure-code', request.data[key]);
        }
      }
    }
    return request;
  }, (err) => Promise.reject(err));
}
