






























































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Sidebar extends Vue {
  @Prop() readonly vendorList!: [];

  @Prop() handelSelect!: Function;

  @Prop() closeSide!: Function;

  @Prop() openSide!: any;

  selected = [];

  allSelected = true;

  indeterminate = false;

  selectedType: string[] = [];

  allSelectedType = true;

  indeterminateType = false;

  gameType = ['Slot', 'Fishing', 'Casino & Card', 'Arcade', 'Other'];

  created() {
    this.selectedType = this.gameType;
  }

  toggleAll(checked: any) {
    this.selected = checked ? this.vendorList.slice() : [];
    this.handelSelect(this.selected, this.selectedType);
  }

  handleVendor(newVal: any) {
    if (newVal.length === 0) {
      this.indeterminate = false;
      this.allSelected = false;
    } else if (newVal.length === this.vendorList.length) {
      this.indeterminate = false;
      this.allSelected = true;
    } else {
      this.indeterminate = true;
      this.allSelected = false;
    }
    this.handelSelect(
      this.selected.sort((a: any, b: any) => b.localeCompare(a)),
      this.selectedType,
    );
  }

  toggleAllType(checked: any) {
    this.selectedType = checked ? this.gameType.slice() : [];
    this.handelSelect(this.selected, this.selectedType);
  }

  handleType(newVal: any) {
    if (newVal.length === 0) {
      this.indeterminateType = false;
      this.allSelectedType = false;
    } else if (newVal.length === this.gameType.length) {
      this.indeterminateType = false;
      this.allSelectedType = true;
    } else {
      this.indeterminateType = true;
      this.allSelectedType = false;
    }
    this.handelSelect(
      this.selected.sort((a: any, b: any) => b.localeCompare(a)),
      this.selectedType,
    );
  }

  @Watch('vendorList', { immediate: true, deep: true })
  onChange() {
    if (this.vendorList.length) {
      this.selected = this.vendorList;
    }
  }
}
