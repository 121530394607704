























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AuthModule from '@/store/modules/Auth';
import serviceUtils from '@/services/utils';

@Component
export default class GameList extends Vue {
  AuthInstance = getModule(AuthModule, this.$store);

  @Prop() gameLists!: [];

  @Prop() LaunchGame!: Function;

  onLogin = serviceUtils.getUserPersistent().token;
}
