




























import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GameModule from '@/store/modules/Games';
import AuthModule from '@/store/modules/Auth';
import VendorModule from '@/store/modules/Vendors';
import serviceUtils from '@/services/utils';
import Navbar from '@/components/Nav.vue';
import Sidebar from '@/components/Sidebar.vue';
import GameList from '@/components/GameList.vue';
import { WEB_GAME_URL, SECRET_KEY, WEB_BASE_URL } from '@/utils/api';
import jwt from 'jwt-simple';

@Component({
  components: {
    Navbar,
    Sidebar,
    GameList,
  },
})
export default class Home extends Vue {
  GameInstance = getModule(GameModule, this.$store);

  AuthInstance = getModule(AuthModule, this.$store);

  VendorInstance = getModule(VendorModule, this.$store);

  gameLists: any = [];

  storeGameList: any = [];

  vendorList: string[] = [];

  firstToken = serviceUtils.getUserPersistent().token;

  componentKeys = 0;

  openSide = false;

  userKey = '';

  async created() {
    if (!this.firstToken) {
      this.fetchDemo();
      this.fetchVendorDemo();
    } else {
      Vue.axios.defaults.headers.common.Authorization = `Bearer ${this.firstToken}`;
      this.fetchGame(this.firstToken);
      this.fetchVendor(this.firstToken);
    }
    this.componentKeys += 1;
  }

  getToken(token: any) {
    this.fetchGame(token);
    this.fetchVendor(token);
    this.firstToken = token;
  }

  async fetchVendorDemo() {
    const resVendor = await this.VendorInstance.FetchVendorDemo();
    this.vendorList = resVendor.provider
      .filter((v: any) => v.isReady === true)
      // eslint-disable-next-line no-nested-ternary, no-confusing-arrow
      .map((v: any) => v.name.charAt(0).toUpperCase() + v.name.slice(1) === 'Ds'
        ? 'Askmebet'
        : v.name.charAt(0).toUpperCase() + v.name.slice(1) === 'Ambslot'
          ? 'Askmeslot'
          : v.name.charAt(0).toUpperCase() + v.name.slice(1))
      .sort();
  }

  async fetchVendor(token: any) {
    if (token) {
      const resVendor = await this.VendorInstance.FetchVendor();
      this.vendorList = resVendor.provider.providerList
        .filter((v: any) => v.isReady === true)
        // eslint-disable-next-line no-nested-ternary, no-confusing-arrow
        .map((v: any) => v.name.charAt(0).toUpperCase() + v.name.slice(1) === 'Ds'
          ? 'Askmebet'
          : v.name.charAt(0).toUpperCase() + v.name.slice(1) === 'Ambslot'
            ? 'Askmeslot'
            : v.name.charAt(0).toUpperCase() + v.name.slice(1))
        .sort();
    }
    this.componentKeys += 1;
  }

  async fetchDemo() {
    const res = await this.GameInstance.FetchGameListDemo();
    this.gameLists = [
      {
        provider: 'All Vendor & Games',
        game: res.gamesList,
      },
    ];
    this.storeGameList = res.gamesList;
    this.componentKeys += 1;
  }

  async fetchGame(token: any) {
    if (token) {
      const res = await this.GameInstance.FetchGameList();
      this.gameLists = [
        {
          provider: 'All Vendor & Games',
          game: res._gamesList,
        },
      ];
      this.storeGameList = res._gamesList;
    }
    this.componentKeys += 1;
  }

  handelSelect(vendor: any, type: any) {
    let fillVendor: any[];
    // eslint-disable-next-line no-nested-ternary
    const _vendor = vendor.map((name: any) => (name === 'Askmebet'
      ? 'ds'
      : name === 'Askmeslot'
        ? 'ambslot'
        : name
    ));
    if (_vendor.length || type.length) {
      if (_vendor.length) {
        fillVendor = _vendor.map((v: any) => ({
          provider: v,
          game: this.storeGameList.filter((g: any) => v.toLowerCase() === g.provider),
        }));
        this.gameLists = fillVendor;
      }
      if (type.length && _vendor.length) {
        const convertType = type.map((t: any) => (t === 'Casino & Card' ? 'casinocard' : t.toLowerCase()));
        if (_vendor.length) {
          const filterGame = _vendor.map((v: any) => ({
            provider: v,
            game: this.storeGameList.filter(
              (w: any) => v.toLowerCase().includes(w.provider) && [...convertType].includes(w.type),
            ),
          }));
          this.gameLists = [...filterGame];
        } else {
          fillVendor = convertType.map((v: any) => ({
            provider: v,
            game: this.storeGameList.filter((g: any) => v === g.type),
          }));
          this.gameLists = fillVendor;
          if (!_vendor.length) {
            this.gameLists = [];
          }
        }
      }
    } else {
      this.gameLists = [];
    }
    if (!vendor.length) {
      this.gameLists = [];
    }
    this.componentKeys += 1;
  }

  async LaunchGame(code: any) {
    if (this.firstToken) {
      const payload = {
        token: this.firstToken,
        gameCode: code.gameCode,
        provider: code.provider,
        gameType: code.type,
        gameName: code.name.en,
      };
      this.userKey = jwt.encode(payload, SECRET_KEY, 'HS256');

      const strWindowFeatures = 'top=0,left=0,scrollbars=no';
      window.open(`${WEB_GAME_URL}?userKey=${this.userKey}&link=${WEB_BASE_URL}`, '_blank', strWindowFeatures);
    } else if (!this.firstToken && code.isDemo) {
      const res = await this.GameInstance.LaunchGameDemo({
        gameCode: code.gameCode,
        provider: code.provider,
      });
      const strWindowFeatures = 'top=0,left=0,height=768,width=1024,scrollbars=no';
      window.open(res, code.provider.toUpperCase(), strWindowFeatures);
    }
  }

  onLogout(token: any) {
    this.firstToken = token;
    this.fetchDemo();
  }

  filterHandle(val: any) {
    if (this.openSide) {
      this.openSide = false;
    } else {
      this.openSide = val;
    }
  }

  closeSide(val: any) {
    this.filterHandle(val);
  }
}
