import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';

import {
  BASE_URL,
  GET_GAMES,
  GET_GAMES_DEMO,
  LAUNCH_GAME,
  LAUNCH_GAME_DEMO,
  M_POST,
} from '@/utils/api';

import Vue from 'vue';

@Module({ name: 'games' })
export default class GameLists extends VuexModule {
  GameList = [];

  GameListDemo = [];

  @Mutation
  mutateGameList(GameList: any) {
    this.GameList = GameList;
  }

  @Mutation
  mutateGameListDemo(GameList: any) {
    this.GameListDemo = GameList;
  }

  @Action({ commit: 'mutateGameList' })
  FetchGameList() {
    return Vue.axios({
      url: BASE_URL + GET_GAMES,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code, message } = data.status;
        if (code === 0) {
          return data.data;
        }
        throw message;
      })
      .catch((err) => err);
  }

  @Action
  FetchGameListDemo() {
    return Vue.axios({
      url: BASE_URL + GET_GAMES_DEMO,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code, message } = data.status;
        if (code === 0) {
          return data.data;
        }
        throw message;
      })
      .catch((err) => err);
  }

  @Action
  LaunchGame(param: any) {
    return Vue.axios({
      url: BASE_URL + LAUNCH_GAME,
      data: param.data,
      method: M_POST,
      headers: {
        Authorization: `Bearer ${param.token}`,
      },
    })
      .then(({ data }) => {
        const { code, message } = data.status;
        if (code === 0) {
          return data.data.url;
        }
        throw message;
      })
      .catch((err) => err);
  }

  @Action
  LaunchGameDemo(param: any) {
    return Vue.axios({
      url: BASE_URL + LAUNCH_GAME_DEMO,
      data: param,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code, message } = data.status;
        if (code === 0) {
          return data.data.url;
        }
        throw message;
      })
      .catch((err) => err);
  }
}
