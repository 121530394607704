import {
  Module,
  VuexModule,
  Mutation,
  Action,
  MutationAction,
} from 'vuex-module-decorators';

import {
  BASE_URL,
  LOGIN,
  M_POST,
  GET_BALANCE,
} from '@/utils/api';

import serviceUtils from '@/services/utils';

import Vue from 'vue';

@Module({ name: 'auth' })
export default class Auth extends VuexModule {
  token: string = serviceUtils.getUserPersistent().token || '';

  balance: number = serviceUtils.getUserBalance() || 0;

  user = serviceUtils.getUserPersistent().user || {};

  @Mutation
  mutateLogin(data: any) {
    const { token, balance, user } = data;
    this.token = token;
    this.balance = balance;
    this.user = user;
  }

  @Action
  Login(userdata: any) {
    return Vue.axios({
      url: BASE_URL + LOGIN,
      data: userdata,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code } = data.status;
        if (code === 0) {
          if (data.data.user.role !== '') {
            const { token, user: { wallet: { balance } }, user } = data.data;
            Vue.prototype.$session.start();
            Vue.prototype.$session.set('middleware-user-authenticate', JSON.stringify(data.data));
            Vue.prototype.$session.set('middleware-user-balance', balance);
            Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            const model = {
              token,
              balance,
              user,
            };
            this.context.commit('mutateLogin', model);
            return data;
          }
          return code;
        }
        throw data;
      })
      .catch((err) => err);
  }

  @MutationAction({ mutate: ['token'] })
  async Logout() {
    serviceUtils.clearUserPersistent();
    return {
      token: '',
    };
  }

  @Action
  getBalance(token: any) {
    return Vue.axios({
      url: BASE_URL + GET_BALANCE,
      data: token,
      method: M_POST,
    })
      .then(({ data }) => data.data)
      .catch((err) => err);
  }
}
