import {
  Module,
  VuexModule,
  Mutation,
  Action,
} from 'vuex-module-decorators';

import {
  BASE_URL,
  GET_VERDOR,
  GET_VERDOR_DEMO,
  M_POST,
} from '@/utils/api';

import Vue from 'vue';

@Module({ name: 'vendor' })
export default class Vendors extends VuexModule {
  vendor = [];

  vendorDemo = [];

  @Mutation
  mutateVendor(vendor: any) {
    this.vendor = vendor;
  }

  @Mutation
  mutateVendorDemo(vendor: any) {
    this.vendorDemo = vendor;
  }

  @Action({ commit: 'mutateVendor' })
  FetchVendor() {
    return Vue.axios({
      url: BASE_URL + GET_VERDOR,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code, message } = data.status;
        if (code === 0) {
          return data.data;
        }
        throw message;
      })
      .catch((err) => err);
  }

  @Action({ commit: 'mutateVendorDemo' })
  FetchVendorDemo() {
    return Vue.axios({
      url: BASE_URL + GET_VERDOR_DEMO,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code, message } = data.status;
        if (code === 0) {
          return data.data;
        }
        throw message;
      })
      .catch((err) => err);
  }
}
